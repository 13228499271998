import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import CustomBreadcrumb from '../../components/CustomBreadcrumb';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import ArticleCard from '../../components/resources/ArticleCard';
import HrefLangManager from '../../components/HrefLangManager'

export default function AllArticles({ pageContext, location }) {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	return (
		<StaticQuery
			query={graphql`
				query AllArticlesQuery {
					allMdx(limit: 1000, filter: { internal: { contentFilePath: { regex: "/resources-post/" } } }) {
						nodes {
							frontmatter {
								category
								slug
								articleCardTitle
								articleCardDescription
							}
						}
					}
				}
			`}
			render={({ allMdx: { nodes } }) => (
				<Layout>
					<Seo title="All hear.com Articles Archives | hear.com" description="All hear.com articles about hearing aids and hearing loss. Read to make the best decision before you purchase hearing aids." />
					<HrefLangManager
						alternates={[
							['x-default', 'https://www.hear.com/resources/all-articles/'],
							['en', 'https://www.hear.com/resources/all-articles/'],
							['en-US', 'https://www.hear.com/resources/all-articles/'],
							['en-CA', 'https://ca.hear.com/resources/all-articles/']
						]}
					/>
					<div className="o-wrapper">
						<div className="o-row-2p5">
							<h1 className="c-resource-title">All Articles</h1>
						</div>
						<div className="o-row-2p5">
							<CustomBreadcrumb location={location} crumbs={crumbs} />
						</div>
						<div className="o-row-4">
							<div className="c-resource-cards-wrapper">
								{nodes.map((post, i) => (
									<ArticleCard
										key={i}
										url={`/resources/${post.frontmatter.category}/${post.frontmatter.slug}`}
										title={post.frontmatter.articleCardTitle}
										description={post.frontmatter.articleCardDescription}
									/>
								))}
							</div>
						</div>
					</div>
				</Layout>
			)}
		/>
	);
}
